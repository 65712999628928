<template>
  <div class="loginbg"></div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
    };
  },
  mounted() {
    if (!window.location.search) {
      this.$message({
            type: 'error',
            showClose: true,
            message: '请从高指中心登录',
            duration:0
          })
    } 
    
  },
  
  
};
</script>

<style lang="scss" scoped>
.loginbg{
  background: url("../../assets/images/common/loginbg.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}
</style>
